import React, { useState, useEffect, useRef } from 'react'
import { TimelineMax, Power1 } from 'gsap'
import classnames from 'classnames'

import AnimatedButton from 'components/buttons/animated-button'
import styles from './job-listings.module.css'

const JobPost = ({ title, acf, slug }) => {
  const [isOpen, toggleOpen] = useState(false)
  const [animation, setAnimation] = useState(null)
  const content = useRef(null)

  useEffect(() => {
    const height = content.current.offsetHeight
    const text = content.current.children[0]
    const button = content.current.children[1]

    const a = new TimelineMax({ paused: true })
    a.set(content.current, { position: 'relative', height: 0, opacity: 1, pointerEvents: 'all' })
    a.set(text, { opacity: 0, y: -20 })
    a.set(button, { opacity: 0, y: -30 })
    a.to(content.current, 0.4, { height, ease: Power1.easeInOut }, 0)
    a.to(text, 0.5, { opacity: 1, y: 0, ease: Power1.easeInOut }, 0.1)
    a.to(button, 0.5, { opacity: 1, y: 0, ease: Power1.easeInOut }, 0.2)

    setAnimation(a)
  }, [])

  const handleClick = () => {
    if (isOpen) {
      animation.reverse()
    } else {
      animation.play()
    }

    toggleOpen(!isOpen)
  }

  return (
    <div className={styles.jobPost}>
      <h4 className={styles.jobTitle} onClick={handleClick}>
        <span className={styles.jobTitleText}>{ title }</span>
        <span className={classnames(styles.icon, isOpen && styles.iconOpen)}></span>
      </h4>

      <div className={styles.contentWrapper} ref={content}>
        <div dangerouslySetInnerHTML={{ __html: acf.blurb }} className={styles.content}></div>
        <AnimatedButton to={`/careers/${slug}`} className={styles.button} />
      </div>
    </div>
  )
}

const JobListings = ({ title, jobs }) => {
  const jobPosts = jobs.map(job => (
    <JobPost key={job.node.slug} {...job.node}/>
  ))

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{ title }</h2>
      
      <div className={styles.jobListings}>
        { jobPosts }
      </div>
    </div>
  )
}

export default JobListings