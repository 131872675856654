import React from 'react'

import styles from './job-listings.module.css'

const JobNoListings = ({ title }) => (
  <div className={styles.container}>
    <h2 className={styles.title}>{ title }</h2>
  </div>
)

export default JobNoListings